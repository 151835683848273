/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'


export function getcardPropertyList(params) {
    return request({
        url: "/card/attribute/list.do?",
        method: 'post',
        data: params
    })
}

export function importBatchAdd(params){
    return request({
        url: '/card/attribute/batchAdd.do?',
        method: 'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
export function attributeAdd(params) {
    return request({
        url: "/card/attribute/add.do?",
        method: 'post',
        data: params
    })
}

export function attributeEdit(params) {
    return request({
        url: "/card/attribute/edit.do?",
        method: 'post',
        data: params
    })
}




