<template>
<div class="cardPropertySetting fadeInRight">
    <div class="title-box clearfix">
        <span class="m-title">不允许实名</span>
        <el-form ref="quaryForm" inline class="right themed-form search-form" style="line-height: 40px;">
            <el-form-item>
                    <el-input v-model="quaryForm.iccidMark" placeholder="ICCID" class="iccid-width"></el-input>
                </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" class="themed-button" @click="onSearch">搜索查询</el-button>
            </el-form-item>
             <el-form-item>
                <el-button type="info" icon="el-icon-plus" class="themed-button" @click="addSetting">新增</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="info" icon="el-icon-plus" class="themed-button" @click="importByExcel">批量导入</el-button>
            </el-form-item>
        </el-form>
    </div>
    
    <div class="content-box">
            <el-table
                border
                ref="table"
                stripe
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccidMark"
                    label="ICCID:"
                    min-width="180"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    prop="notAllowRn"
                    label="不允许实名"
                    min-width="180"
                    >
                      <template slot-scope="scope">
                       <span v-if="scope.row.notAllowRn">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    min-width="100">
                    <template slot-scope="scope">
                      <el-button type="primary" class="themed-button" @click="updateSetting(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
              
                   
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
          <el-dialog
      title="新增"
      :visible.sync="dialogAddVisible"
      width="600"
      v-if="dialogAddVisible"
    >
      <el-form :model="checkForm" ref="checkForm">
        <el-form-item label="ICCID:" required  prop="iccidMark" :label-width="formLabelWidth">
          <el-input
            v-model="checkForm.iccidMark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      <el-form-item label="不允许实名:"   prop="notAllowRn"  :label-width="formLabelWidth">
                <el-radio v-model="checkForm.notAllowRn" label="true">是</el-radio>
                <el-radio v-model="checkForm.notAllowRn" label="false">否</el-radio>
            </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="success" @click="sureChecked()"
          >确 认</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="dialogAddVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>

          <el-dialog
      title="编辑"
      :visible.sync="dialogEditVisible"
      width="600"
      v-if="dialogEditVisible"
    >
      <el-form :model="editForm" ref="editForm">
        <el-form-item label="ICCID" required prop="iccidMark" :label-width="formLabelWidth">
          <el-input
            v-model="editForm.iccidMark"
            autocomplete="off" 
          ></el-input>
        </el-form-item>
      <el-form-item label="不允许实名:" required  prop="notAllowRn" :label-width="formLabelWidth">
                <el-radio v-model="editForm.notAllowRn" label="true">是</el-radio>
                <el-radio v-model="editForm.notAllowRn" label="false">否</el-radio>
            </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="themed-button" type="success" @click="editChecked()"
          >确 认</el-button
        >
        <el-button
          class="themed-button"
          type="primary"
          @click="dialogEditVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="批量导入" :visible.sync="dialogBoxImport">
        <el-form label-position="right" label-width="120px">
            <el-form-item label="选择excel文件:" required>
                <input class="file-input" type="file" ref='file'/>
                <div class="mt5">
                    <span class="text-color" style="font-size: 12px;">仅支持.xls/.xlsx格式</span>
                </div>
            </el-form-item>
            <el-form-item label="不允许实名:" required >
                <el-radio v-model="notAllowRn" label="true">是</el-radio>
                <el-radio v-model="notAllowRn" label="false">否</el-radio>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="uploadExcel">提交</el-button>
            <el-button type="primary" class="themed-button" @click="exportTemplateExcel('importIccid.xls')">导出模板</el-button>
            <el-button type="primary" class="themed-button" @click="dialogBoxImport = false">取 消</el-button>
        </div>
    </el-dialog>

</div>

</template>

<script type="text/ecmascript-6">
import Utils from '@/utils/utils.js'
import resize from '@/mixins/resize.js'
import {messageBox,confirmBox} from '@/utils/common.js'
import {exportTemplate} from '@/api/statistics.js'
import {getcardPropertyList,importBatchAdd,attributeAdd,attributeEdit} from '@/api/virtualCard/cardPropertySetting.js'


export default {
    mixins:[resize],
    data(){
        return {
           quaryForm:{
               iccidMark:'',
           },
           dialogBoxImport:false,
           tableData:[],
            page:1,
            pageSize:15,
            total:0,
            notAllowRn:"false",
            dialogAddVisible:false,
            checkForm:{
               iccidMark:"",
               notAllowRn:"false",
            },
             editForm:{
               iccidMark:"",
               notAllowRn:"false",
            },
            dialogEditVisible:false,
            formLabelWidth:"120px"
        }
    },
    mounted(){
        this.getcardPropertyList()
    },
    methods:{
        sureChecked(){
          if(this.checkForm.iccidMark.trim()==""){
              messageBox(this,'请输入iccid')
                            return
          }
          attributeAdd(this.checkForm).then(res=>{
             if (res.system_result_key == '0') {
                 this.dialogAddVisible = false
                 this.getcardPropertyList()
                  messageBox(this,'添加成功')
             }else{
                 this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
             }
          })
        },
        addSetting(){
         this.dialogAddVisible = true
         this.checkForm.iccidMark=""
         this.checkForm.notAllowRn="false"
        },
        updateSetting(row){
            console.log(row)
          this.dialogEditVisible = true
          this.editForm.iccidMark=row.iccidMark
          this.editForm.notAllowRn =row.notAllowRn?row.notAllowRn+"":"false"
        },
        editChecked(){
        attributeEdit(this.editForm).then(res=>{
             if (res.system_result_key == '0') {
                 this.dialogEditVisible = false
                 this.getcardPropertyList()
                  messageBox(this,'编辑成功')
             }else{
                 this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
             }
          })

        },
        getcardPropertyList(){
            let params = {
                pageNo:this.page,
                pageSize:this.pageSize
            }
            Object.assign(this.quaryForm,params)
            getcardPropertyList(this.quaryForm).then(res=>{
                this.tableData=res.rows
                this.total = res.count
            })
        },
        exportTemplateExcel(name){
            exportTemplate({name}).then(res=>{
                const filename = name
                Utils.createDownload(res,filename)
            })
        },

        importByExcel(){
            this.dialogBoxImport = true
        },
        uploadExcel(){
            confirmBox(this,'确定导入吗？','操作提示',()=>{
                this.comfirmImport()
            })
        },
        comfirmImport(){
            console.log("aaaa")
            let file = this.$refs['file'].files[0];
            if(file){
                let fileName = file.name
                let index = fileName.lastIndexOf(".");
                if(index < 0 || !(fileName.substring(index + 1, fileName.length)=='xls'|| fileName.substring(index + 1, fileName.length)=='xlsx')){
                    messageBox(this,'文件格式错误')
                    return
                }else if(!!Utils.checkFile(file)){
                      if(Utils.checkFile(file)==1){
                         messageBox(this,'上传文件不能超过20M')
                            return
                      }else if(Utils.checkFile(file)==2){
                          messageBox(this,'上传文件名称长度不能超过36个字符')
                            return
                      }
                }else{
                    let params = new FormData()
                    params.append('fileurl',file)
                    params.append('notAllowRn',this.notAllowRn)
                    importBatchAdd(params).then(res=>{
                        if (res.system_result_key == '0') {
                            this.$alert('导入成功，请到导入记录中下载', '提示', {
                                confirmButtonText:'确定',
                            }).then(()=>{
                                this.dialogBoxImport = false
                                this.$router.push('/tob/i_import_list')
                            });
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
                    })
                }
            }else{
                messageBox(this,'请选择文件')
                return
            }
        },
       onPageChange(page){
			this.page = page
			this.getcardPropertyList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getcardPropertyList()
        },

        onSearch(){
            this.page=1
            this.getcardPropertyList()
        },
    }
}

</script>

